export const TRANSLATION_UPDATED_CARD = 'UPDATED_CARD';
export const TRANSLATION_APP_STATUS_CONFIRMED_BY = 'APP_STATUS_CONFIRMED_BY';
export const TRANSLATION_APP_STATUS_MISSING_INFO = 'APP_STATUS_MISSING_INFO';
export const TRANSLATION_APP_STATUS_LAST_UPDATED = 'APP_STATUS_LAST_UPDATED';
export const TRANSLATION_APP_STATUS_BY = 'APP_STATUS_BY';
export const TRANSLATION_APP_STATUS_CONFIRMED_BTN = 'APP_STATUS_CONFIRMED_BTN';
export const TRANSLATION_APP_STATUS_INFO_COMPLETED =
    'APP_STATUS_INFO_COMPLETED';
export const TRANSLATION_APP_STATUS_RECONFIRM_BTN = 'APP_STATUS_RECONFIRM_BTN';
export const TRANSLATION_APP_STATUS_PERIODIC_UPDATE =
    'APP_STATUS_PERIODIC_UPDATE';
export const TRANSLATION_APP_STATUS_LAST_CONFIRMED_BY =
    'APP_STATUS_LAST_CONFIRMED_BY';
export const TRANSLATION_APP_STATUS_PERIODIC_UPDATE_DUE =
    'APP_STATUS_PERIODIC_UPDATE_DUE';
export const TRANSLATION_APP_STATUS_NEXT_REVIEW_DATE =
    'APP_STATUS_NEXT_REVIEW_DATE';
export const TRANSLATION_APP_STATUS_SET_DUE_DATE = 'APP_STATUS_SET_DUE_DATE';
export const TRANSLATION_CASE_SLA_DUE = 'CASE_SLA_DUE';
export const TRANSLATION_CASE_NO_SLA = 'CASE_NO_SLA';
export const TRANSLATION_CASE_SLA_OVERDUE = 'CASE_SLA_OVERDUE';
export const TRANSLATION_CASE_COMPLETED = 'CASE_COMPLETED';
export const TRANSLATION_CASE_COMPLETED_WITHIN_SLA =
    'CASE_COMPLETED_WITHIN_SLA';
export const TRANSLATION_CASE_COMPLETED_OUTSIDE_SLA =
    'CASE_COMPLETED_OUTSIDE_SLA';
export const TRANSLATION_CASEWORK_STATUS_COMPLETE = 'CASEWORK_STATUS_COMPLETE';
export const TRANSLATION_CASEWORK_STATUS_HAS_ISSUE =
    'CASEWORK_STATUS_HAS_ISSUE';
export const TRANSLATION_CASEWORK_STATUS_OVERDUE = 'CASEWORK_STATUS_OVERDUE';
export const TRANSLATION_CASEWORK_STATUS_DUE_SOON = 'CASEWORK_STATUS_DUE_SOON';
export const TRANSLATION_CASEWORK_STATUS_DUE_LATER =
    'CASEWORK_STATUS_DUE_LATER';
export const TRANSLATION_CASEWORK_STATUS_NO_DEADLINE =
    'CASEWORK_STATUS_NO_DEADLINE';
export const TRANSLATION_CASEWORK_STATUS_CANCELLED =
    'CASEWORK_STATUS_CANCELLED';
export const TRANSLATION_CASE_NEXT_ACTION = 'CASE_NEXT_ACTION';
export const TRANSLATION_CASE_ALL_ACTIONS_ARE_CANCELLED =
    'CASE_ALL_ACTIONS_ARE_CANCELLED';
export const TRANSLATION_CASE_ALL_ACTIONS_COMPLETED =
    'CASE_ALL_ACTIONS_COMPLETED';
export const TRANSLATION_APP_CASE_CREATED = 'CASE_CREATED';
export const TRANSLATION_OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB';
export const TRANSLATION_ASSIGN_TO = 'ASSIGN_TO';
export const TRANSLATION_DELEGATE_ACCESS = 'DELEGATE_ACCESS';
export const TRANSLATION_CANCEL = 'CANCEL';
export const TRANSLATION_AREA_RECENTRECORDS = 'AREA_RECENTRECORDS';
export const TRANSLATION_NO_RECENT_RECORDS = 'NO_RECENT_RECORDS';
export const TRANSLATION_SVG_TEXT_NO_RECORD_FOUND = 'SVG_TEXT_NO_RECORD_FOUND';
export const TRANSLATION_ENUM_TABTYPE_KYCSTATUS = 'ENUM_TABTYPE_KYCSTATUS';
export const TRANSLATION_UPDATE_KYC_BTN = 'UPDATE_KYC_BTN';
export const TRANSLATION_LINK_PENDINGCHANGES = 'LINK_PENDINGCHANGES';
export const TRANSLATION_CREATE_NEW = 'CREATE_NEW';
export const TRANSLATION_ENTITY_SAVEDFILTER_PLURAL =
    'ENTITY_SAVEDFILTER_PLURAL';
export const TRANSLATION_MENU_SEEMORE = 'MENU_SEEMORE';
export const TRANSLATION_MENU_SEE_LESS = 'MENU_SEE_LESS';
export const TRANSLATION_USER_FEEDBACK_NO_RESULTS = 'USER_FEEDBACK_NO_RESULTS';
export const TRANSLATION_ENTITY_TEAM_SINGULAR = 'ENTITY_TEAM_SINGULAR';
export const TRANSLATION_ENUM_TABTYPE_TEAMMEMBERS = 'ENUM_TABTYPE_TEAMMEMBERS';
export const TRANSLATION_SVG_TEXT_NO_TEAM_MEMBERS = 'SVG_TEXT_NO_TEAM_MEMBERS';
export const TRANSLATION_ENTITY_OPPORTUNITY_PLURAL =
    'ENTITY_OPPORTUNITY_PLURAL';
export const TRANSLATION_SEE_ALL_OPPORTUNITIES_BTN =
    'SEE_ALL_OPPORTUNITIES_BTN';
export const TRANSLATION_WELCOME_SUBTITLE = 'WELCOME_SUBTITLE';
export const TRANSLATION_COMPLETE_CASE_BTN = 'COMPLETE_CASE_BTN';
export const TRANSLATION_PARENT_CASE_BADGE = 'PARENT_CASE_BADGE';
export const TRANSLATION_CASE_COMPLATED_DATE = 'CASE_COMPLATED_DATE';
export const TRANSLATION_CASE_ALL_ACTIONS_CANCELLED =
    'CASE_ALL_ACTIONS_CANCELLED';
export const TRANSLATION_ADD_ACTIVITY_BTN = 'ADD_ACTIVITY_BTN';
export const TRANSLATION_ENUM_TABTYPE_WORK_LIST = 'ENUM_TABTYPE_WORK_LIST';
export const TRANSLATION_ENUM_TABTYPE_CASE_DETAILS =
    'ENUM_TABTYPE_CASE_DETAILS';
export const TRANSLATION_ENUM_TABTYPE_RELATED_RECORDS =
    'ENUM_TABTYPE_RELATED_RECORDS';
export const TRANSLATION_ENUM_TABTYPE_DOCUMENTS = 'ENUM_TABTYPE_DOCUMENTS';
export const TRANSLATION_ENUM_TABTYPE_TIMELINE = 'ENUM_TABTYPE_TIMELINE';
export const TRANSLATION_ENTITY_NOTE_PLURAL = 'ENTITY_NOTE_PLURAL';
export const TRANSLATION_SEE_ALL_LIST_MEMBERSHIPS_BTN =
    'SEE_ALL_LIST_MEMBERSHIPS_BTN';
export const TRANSLATION_ENTITY_CLIENTPRODUCT_PLURAL =
    'ENTITY_CLIENTPRODUCT_PLURAL';
export const TRANSLATION_SEE_ALL_CASES_BTN = 'SEE_ALL_CASES_BTN';
export const TRANSLATION_ENTITY_DOCUMENT_PLURAL = 'ENTITY_DOCUMENT_PLURAL';
export const TRANSLATION_ENTITY_CASE_PLURAL = 'ENTITY_CASE_PLURAL';
export const TRANSLATION_SEE_ALL_DOCUMENTS_BTN = 'SEE_ALL_DOCUMENTS_BTN';
export const TRANSLATION_ENTITY_FEED_PLURAL = 'ENTITY_FEED_PLURAL';
export const TRANSLATION_LABEL_MEMBERSHIPS = 'LABEL_MEMBERSHIPS';
export const TRANSLATION_LABEL_PORTFOLIO_INSIGHTS = 'LABEL_PORTFOLIO_INSIGHTS';
export const TRANSLATION_FILTER_PERSONAL_VIEW = 'FILTER_PERSONAL_VIEW';
export const TRANSLATION_FILTER_SYSTEM_VIEW = 'FILTER_SYSTEM_VIEW';
export const TRANSLATION_SEARCH_CASES = 'SEARCH_CASES';
export const TRANSLATION_ENTITY_CASE_SINGULAR = 'ENTITY_CASE_SINGULAR';
export const TRANSLATION_SORT_MENU_CREATED_ON = 'SORT_MENU_CREATED_ON';
export const TRANSLATION_SORT_DUE = 'SORT_MENU_DUE';
export const TRANSLATION_SORT_MENU_NEXT_ACTION_DUE =
    'SORT_MENU_NEXT_ACTION_DUE';
export const TRANSLATION_ACTION_DOCUMENTTYPE_DEACTIVATE =
    'ACTION_DOCUMENTTYPE_DEACTIVATE';
export const TRANSLATION_WORK_LIST_SELECT_ITEM = 'WORK_LIST_SELECT_ITEM';
export const TRANSLATION_WORK_LIST_ALL_ACTIONS_CANCELLED =
    'WORK_LIST_ALL_ACTIONS_CANCELLED';
export const TRANSLATION_WORK_LIST_CASE_COMPLETED_SELECT_ITEM =
    'WORK_LIST_CASE_COMPLETED_SELECT_ITEM';
export const TRANSLATION_GROUP_BY = 'GROUP_BY';
export const TRANSLATION_GROUP_BY_MENU_STAGE = 'GROUP_BY_MENU_STAGE';
export const TRANSLATION_GROUP_BY_MENU_ASSIGNEE = 'GROUP_BY_MENU_ASSIGNEE';
export const TRANSLATION_GROUP_BY_MENU_STATUS_DETAIL =
    'GROUP_BY_MENU_STATUS_DETAIL';
export const TRANSLATION_GROUP_BY_MENU_STATUS = 'GROUP_BY_MENU_STATUS';
export const TRANSLATION_GROUP_BY_MENU_RELATED_RECORD =
    'GROUP_BY_MENU_RELATED_RECORD';
export const TRANSLATION_ACTIVITY_CREATED = 'ACTIVITY_CREATED';
export const TRANSLATION_ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED';
export const TRANSLATION_ACTIVITY_NOT_FILED = 'ACTIVITY_NOT_FILED';
export const TRANSLATION_TAB_COMMENTS = 'TAB_COMMENTS';
export const TRANSLATION_TAB_CHAT = 'TAB_CHAT';
export const TRANSLATION_TAB_NOTES = 'TAB_NOTES';
export const TRANSLATION_TAB_CONTENT = 'TAB_CONTENT';
export const TRANSLATION_DOCK_ACTIVITY = 'DOCK_ACTIVITY';
export const TRANSLATION_ACTIVITY_NO_CHAT = 'ACTIVITY_NO_CHAT';
export const TRANSLATION_STATUS_TAG_OVERDUE = 'STATUS_TAG_OVERDUE';
export const TRANSLATION_STATUS_TAG_CANCELLED = 'STATUS_TAG_CANCELLED';
export const TRANSLATION_STATUS_TAG_RECEIVED = 'STATUS_TAG_RECEIVED';
export const TRANSLATION_STATUS_TAG_COMPLETE = 'STATUS_TAG_COMPLETE';
export const TRANSLATION_STATUS_TAG_ENDED = 'STATUS_TAG_ENDED';
export const TRANSLATION_STATUS_TAG_OPEN = 'STATUS_TAG_OPEN';
export const TRANSLATION_STATUS_TAG_SENT = 'STATUS_TAG_SENT';
export const TRANSLATION_STATUS_TAG_DRAFT = 'STATUS_TAG_DRAFT';
export const TRANSLATION_STATUS_TAG_CONFIRMED = 'STATUS_TAG_CONFIRMED';
export const TRANSLATION_STATUS_TAG_SUBMITTED = 'STATUS_TAG_SUBMITTED';
export const TRANSLATION_STATUS_TAG_NOT_STARTED = 'STATUS_TAG_NOT_STARTED';
export const TRANSLATION_STATUS_TAG_READY = 'STATUS_TAG_READY';
export const TRANSLATION_STATUS_TAG_CANNOT_START = 'STATUS_TAG_CANNOT_START';
export const TRANSLATION_SELECT_AN_ACTIVITY = 'SELECT_AN_ACTIVITY';
export const TRANSLATION_BACK_TO_ACTIVITY = 'BACK_TO_ACTIVITY';
export const TRANSLATION_CLOSE_POPOVER = 'CLOSE_POPOVER';
export const TRANSLATION_RELATED_PARTY_POPOVER = 'RELATED_PARTY_POPOVER';
export const TRANSLATION_ACTIVITY_FORM_LABEL = 'ACTIVITY_FORM_LABEL';
export const TRANSLATION_COMMENTS_LABEL = 'COMMENTS_LABEL';
export const TRANSLATION_NOTES_LABEL = 'NOTES_LABEL';
export const TRANSLATION_LINK_RELATED_LABEL = 'LINK_RELATED_LABEL';
export const TRANSLATION_ENTITY_ACTIVITY_SINGULAR = 'ENTITY_ACTIVITY_SINGULAR';
export const TRANSLATION_ENTITY_OPPORTUNITY_SINGULAR =
    'ENTITY_OPPORTUNITY_SINGULAR';
export const TRANSLATION_ENTITY_ARTICLE_PLURAL = 'ENTITY_ARTICLE_PLURAL';
export const TRANSLATION_MORE_LABEL = 'MORE_LABEL';
export const TRANSLATION_SORT_BY = 'SORT_BY';
export const TRANSLATION_SORT_MENU_CLIENT_VALUE = 'SORT_MENU_CLIENT_VALUE';
export const TRANSLATION_SORT_MENU_URGENCY = 'SORT_MENU_URGENCY';
export const TRANSLATION_SORT_MENU_ESTIMATED_CLOSE =
    'SORT_MENU_ESTIMATED_CLOSE';
export const TRANSLATION_SORT_MENU_VALUE = 'SORT_MENU_VALUE';
export const TRANSLATION_SORT_MENU_NAME = 'SORT_MENU_NAME';
export const TRANSLATION_SORT_MENU_RECENTLY_ADDED = 'SORT_MENU_RECENTLY_ADDED';
export const TRANSLATION_SORT_MENU_ALPHABETICAL = 'SORT_MENU_ALPHABETICAL';
export const TRANSLATION_SORT_MENU_DATE_PUBLISHED = 'SORT_MENU_DATE_PUBLISHED';
export const TRANSLATION_SORT_MENU_RECENTLY_UPLOADED =
    'SORT_MENU_RECENTLY_UPLOADED';
export const TRANSLATION_SORT_MENU_ALPHABETICAL_A_Z =
    'SORT_MENU_ALPHABETICAL_A_Z';
export const TRANSLATION_SORT_MENU_ALPHABETICAL_Z_A =
    'SORT_MENU_ALPHABETICAL_Z_A';
export const TRANSLATION_SEARCH_OPPORTUNITIES = 'SEARCH_OPPORTUNITIES';
export const TRANSLATION_ENTITY_DOCUMENT_SINGULAR = 'ENTITY_DOCUMENT_SINGULAR';
export const TRANSLATION_ENTITY_NOTE_SINGULAR = 'ENTITY_NOTE_SINGULAR';
export const TRANSLATION_SEARCH_TASKS = 'SEARCH_TASKS';
export const TRANSLATION_ENTITY_TASK_PLURAL = 'ENTITY_TASK_PLURAL';
export const TRANSLATION_ENTITY_TASK_SINGULAR = 'ENTITY_TASK_SINGULAR';
export const TRANSLATION_INACTIVE_RECORD = 'INACTIVE_RECORD';
export const TRANSLATION_SUGGESTED_CASES = 'SUGGESTED_CASES';
export const TRANSLATION_RELATED_CASES_GUIDANCE_TEXT =
    'RELATED_CASES_GUIDANCE_TEXT';
export const TRANSLATION_RELATED_CASES_OPEN = 'RELATED_CASES_OPEN';
export const TRANSLATION_GUIDANCE_TEXT_RELATED_CASES =
    'GUIDANCE_TEXT_RELATED_CASES';
export const TRANSLATION_SUGGESTED_OPPORTUNITIES = 'SUGGESTED_OPPORTUNITIES';
export const TRANSLATION_RELATED_OPP_GUIDANCE_TEXT =
    'RELATED_OPP_GUIDANCE_TEXT';
export const TRANSLATION_TOAST_MESSAGE_RECORD_CREATED =
    'TOAST_MESSAGE_RECORD_CREATED';
export const TRANSLATION_TOAST_MESSAGE_CLICK_TO_VIEW =
    'TOAST_MESSAGE_CLICK_TO_VIEW';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY =
    'TOAST_MESSAGE_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_ERROR =
    'TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_ERROR';
export const TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR =
    'TOAST_MESSAGE_TEAM_MEMBER_DELETED_HAS_ERROR';
export const TRANSLATION_TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER =
    'TOAST_MESSAGE_REMOVE_PRIMARY_TEAM_MEMBER';
export const TRANSLATION_TOAST_MESSAGE_DOC_UPDATED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_UPDATED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_DELETED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_DELETED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_DELETED_HAS_ERROR =
    'TOAST_MESSAGE_DOC_DELETED_HAS_ERROR';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED =
    'TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED';
export const TRANSLATION_TOAST_MESSAGE_ADD_TO_LIST_SUCCESS =
    'TOAST_MESSAGE_ADD_TO_LIST_SUCCESS';
export const TRANSLATION_TOAST_MESSAGE_DASHBOARD_CLONED =
    'TOAST_MESSAGE_DASHBOARD_CLONED';
export const TRANSLATION_TOAST_MESSAGE_ID_VERIFICATION =
    'TOAST_MESSAGE_ID_VERIFICATION';
export const TRANSLATION_TOAST_MESSAGE_EXPORT_BACKGROUND_JOB =
    'TOAST_MESSAGE_EXPORT_BACKGROUND_JOB';
export const TRANSLATION_TOAST_MESSAGE_ACTION_COMPLETED_SUCCESSFULLY =
    'TOAST_MESSAGE_ACTION_COMPLETED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_FILE_UNSUCCESSFUL =
    'TOAST_MESSAGE_IMPORT_FILE_UNSUCCESSFUL';
export const TRANSLATION_TOAST_MESSAGE_BACKGROUND_JOB_GENERATED =
    'TOAST_MESSAGE_BACKGROUND_JOB_GENERATED';
export const TRANSLATION_TOAST_MESSAGE_OPP_DELETED =
    'TOAST_MESSAGE_OPP_DELETED';
export const TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_REMOVED =
    'TOAST_MESSAGE_TEAM_MEMBER_REMOVED';
export const TRANSLATION_TOAST_MESSAGE_PASSWORD_CHANGED =
    'TOAST_MESSAGE_PASSWORD_CHANGED';
export const TRANSLATION_TOAST_MESSAGE_CONSTENT_ERROR =
    'TOAST_MESSAGE_CONSTENT_ERROR';
export const TRANSLATION_TOAST_MESSAGE_ACCESS_GRANTED =
    'TOAST_MESSAGE_ACCESS_GRANTED';
export const TRANSLATION_TOAST_MESSAGE_RECORD_DELETED =
    'TOAST_MESSAGE_RECORD_DELETED';
export const TRANSLATION_TOAST_MESSAGE_ACTIVITY_CANCELLED =
    'TOAST_MESSAGE_ACTIVITY_CANCELLED';
export const TRANSLATION_TOAST_MESSAGE_GENERATE_DOC_TEMPLATE =
    'TOAST_MESSAGE_GENERATE_DOC_TEMPLATE';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_TEMPALTE_GENERATED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED =
    'TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_LOG_DELETED =
    'TOAST_MESSAGE_IMPORT_LOG_DELETED';
export const TRANSLATION_TOAST_MESSAGE_IMPORT_LOG_DELETED_ERROR =
    'TOAST_MESSAGE_IMPORT_LOG_DELETED_ERROR';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPLATE_UPDATED_STATUS =
    'TOAST_MESSAGE_DOC_TEMPLATE_UPDATED_STATUS';
export const TRANSLATION_TOAST_MESSAGE_DOC_TEMPLATE_DELETED =
    'TOAST_MESSAGE_DOC_TEMPLATE_DELETED';
export const TRANSLATION_TOAST_MESSAGE_FILE_PARSED_SUCCESSFULLY =
    'TOAST_MESSAGE_FILE_PARSED_SUCCESSFULLY';
export const TRANSLATION_TOAST_MESSAGE_ACTION_COMPLETED =
    'TOAST_MESSAGE_ACTION_COMPLETED';
export const TRANSLATION_TOAST_MESSAGE_SENDING_MESSAGE =
    'TOAST_MESSAGE_SENDING_MESSAGE';
export const TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_SENT =
    'TOAST_MESSAGE_SUCCESSFULLY_SENT';
export const TRANSLATION_TOAST_MESSAGE_SENDING_ERROR =
    'TOAST_MESSAGE_SENDING_ERROR';
export const TRANSLATION_TOAST_MESSAGE_REPLYING = 'TOAST_MESSAGE_REPLYING';
export const TRANSLATION_TOAST_MESSAGE_REPLY_SENT = 'TOAST_MESSAGE_REPLY_SENT';
export const TRANSLATION_TOAST_MESSAGE_DOC_MARKED_AS_SENT =
    'TOAST_MESSAGE_DOC_MARKED_AS_SENT';
export const TRANSLATION_TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST =
    'TOAST_MESSAGE_DOC_ATTACHED_TO_CHECKLIST';
export const TRANSLATION_TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST =
    'TOAST_MESSAGE_DOC_REMOVED_FROM_CHECKLIST';
export const TRANSLATION_TOAST_MESSAGE_DOC_TYPE_DELETED_SUCCESSFULLY =
    'TOAST_MESSAGE_DOC_TYPE_DELETED_SUCCESSFULLY';
export const TRANSLATION_SHOW_LABEL = 'SHOW_LABEL';
export const TRANSLATION_RESULTS_LABEL = 'RESULTS_LABEL';
export const TRANSLATION_SHOWING_TABLE_PAGING = 'SHOWING_TABLE_PAGING';
export const TRANSLATION_RECORD_DETAILS_LABEL = 'RECORD_DETAILS_LABEL';
export const TRANSLATION_UNKNOWN_CONTACT_LABEL = 'UNKNOWN_CONTACT_LABEL';
export const TRANSLATION_LAST_UPDATED_LABEL = 'LAST_UPDATED_LABEL';
export const TRANSLATION_ENUM_EXTERNALTASKSTATUSTYPE_NOTSTARTED =
    'ENUM_EXTERNALTASKSTATUSTYPE_NOTSTARTED';
export const TRANSLATION_SEARCH_LABEL = 'SEARCH_LABEL';
export const TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE =
    'TABLE_HEADER_LIFECYCLE_STAGE';
export const TRANSLATION_TABLE_HEADER_NAME = 'TABLE_HEADER_NAME';
export const TRANSLATION_TABLE_HEADER_TYPE = 'TABLE_HEADER_TYPE';
export const TRANSLATION_TABLE_HEADER_SUB_TYPE = 'TABLE_HEADER_SUB_TYPE';
export const TRANSLATION_TABLE_HEADER_LAST_CONTACTED =
    'TABLE_HEADER_LAST_CONTACTED';
export const TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY =
    'TABLE_HEADER_NEXT_ACTIVITY';
export const TRANSLATION_TABLE_HEADER_OPEN_CASES = 'TABLE_HEADER_OPEN_CASES';
export const TRANSLATION_TABLE_HEADER_PIPELINE = 'TABLE_HEADER_PIPELINE';
export const TRANSLATION_TABLE_HEADER_AUM = 'TABLE_HEADER_AUM';
export const TRANSLATION_TABLE_HEADER_LAST_REVIEW = 'TABLE_HEADER_LAST_REVIEW';
export const TRANSLATION_TABLE_HEADER_NEXT_REVIEW = 'TABLE_HEADER_NEXT_REVIEW';
export const TRANSLATION_TABLE_HEADER_COMPLETED_ACTIVITIES =
    'TABLE_HEADER_COMPLETED_ACTIVITIES';
export const TRANSLATION_TABLE_HEADER_PARTY_TYPE = 'TABLE_HEADER_PARTY_TYPE';
export const TRANSLATION_TABLE_HEADER_INVESTABLE_ASSETS =
    'TABLE_HEADER_INVESTABLE_ASSETS';
export const TRANSLATION_TABLE_HEADER_LEVEL = 'TABLE_HEADER_LEVEL';
export const TRANSLATION_TABLE_HEADER_TOTAL_VALUE_INTRODUCED =
    'TABLE_HEADER_TOTAL_VALUE_INTRODUCED';
export const TRANSLATION_TABLE_HEADER_TOTAL_VALUE_MANAGED =
    'TABLE_HEADER_TOTAL_VALUE_MANAGED';
export const TRANSLATION_SHOWING_LABEL = 'SHOWING_LABEL';
export const TRANSLATION_TO_LABEL = 'TO_LABEL';
export const TRANSLATION_OF_LABEL = 'OF_LABEL';
export const TRANSLATION_TABLE_HEADER_DOC_CATEGORY =
    'TABLE_HEADER_DOC_CATEGORY';
export const TRANSLATION_TABLE_HEADER_DOC_TYPE = 'TABLE_HEADER_DOC_TYPE';
export const TRANSLATION_TABLE_HEADER_DOC_NAME = 'TABLE_HEADER_DOC_NAME';
export const TRANSLATION_TABLE_HEADER_START_DATE_VALIDITY =
    'TABLE_HEADER_START_DATE_VALIDITY';
export const TRANSLATION_TABLE_HEADER_END_DATE_VALIDITY =
    'TABLE_HEADER_END_DATE_VALIDITY';
export const TRANSLATION_TABLE_HEADER_SIGN_DATE = 'TABLE_HEADER_SIGN_DATE';
export const TRANSLATION_TABLE_HEADER_FILE_NAME = 'TABLE_HEADER_FILE_NAME';
export const TRANSLATION_TABLE_HEADER_DATE_UPDATED =
    'TABLE_HEADER_DATE_UPDATED';
export const TRANSLATION_TABLE_HEADER_UPLOADED_BY = 'TABLE_HEADER_UPLOADED_BY';
export const TRANSLATION_AREA_HOME = 'AREA_HOME';
export const TRANSLATION_NOTIFICATIONS_MARK_AS_READ =
    'NOTIFICATIONS_MARK_AS_READ';
export const TRANSLATION_VIEW_ALL_NOTIFICATIONS = 'VIEW_ALL_NOTIFICATIONS';
export const TRANSLATION_SVG_ALL_UP_TO_DATE = 'SVG_ALL_UP_TO_DATE';
export const TRANSLATION_SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const TRANSLATION_SAVE_AS_DRAFT_BTN = 'SAVE_AS_DRAFT_BTN';
export const TRANSLATION_POPOVER_FORM_HISTORY = 'POPOVER_FORM_HISTORY';
export const TRANSLATION_POPOVER_BACK_TO_FORM = 'POPOVER_BACK_TO_FORM';
export const TRANSLATION_FORM_ERROR_COMPLETE_MISSING_FIELDS =
    'FORM_ERROR_COMPLETE_MISSING_FIELDS';
export const TRANSLATION_FORM_ERROR_CANNOT_SAVE_MISSING_VALUES =
    'FORM_ERROR_CANNOT_SAVE_MISSING_VALUES';
export const TRANSLATION_CANCEL_BTN = 'CANCEL_BTN';
export const TRANSLATION_SAVE_AND_COMPLETE_BTN = 'SAVE_AND_COMPLETE_BTN';
export const TRANSLATION_SAVE_BTN = 'SAVE_BTN';
export const TRANSLATION_FORM_NO_REQUIRED_FIELDS = 'FORM_NO_REQUIRED_FIELDS';
export const TRANSLATION_FORM_FIELDS_COMPLETED = 'FORM_FIELDS_COMPLETED';
export const TRANSLATION_FORM_CLICK_HERE_BTN = 'FORM_CLICK_HERE_BTN';
export const TRANSLATION_FORM_CLICK_TO_VIEW_VERSION_BTN =
    'FORM_CLICK_TO_VIEW_VERSION_BTN';
export const TRANSLATION_FORM_INFO_YOU_ARE_VIEWING =
    'FORM_INFO_YOU_ARE_VIEWING';
export const TRANSLATION_BY_LABEL = 'BY_LABEL';
export const TRANSLATION_FORM_INFO_VERSION_OF_THIS_FORM =
    'FORM_INFO_VERSION_OF_THIS_FORM';
export const TRANSLATION_NOTIFICATIONS_OTHER_UNREAD_LABEL =
    'NOTIFICATIONS_OTHER_UNREAD_LABEL';
export const TRANSLATION_FORM_NO_HISTORY = 'FORM_NO_HISTORY';
export const TRANSLATION_FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';
export const TRANSLATION_DRAG_AND_DROP_LABEL = 'DRAG_AND_DROP_LABEL';
export const TRANSLATION_OR_LABEL = 'OR_LABEL';
export const TRANSLATION_RETRY_BTN = 'RETRY_BTN';
export const TRANSLATION_QUICK_CREATE = 'QUICK_CREATE';
export const TRANSLATION_IMPORT_OUTLOOK_ITEMS = 'IMPORT_OUTLOOK_ITEMS';
export const TRANSLATION_UPLOAD_OUTLOOK_ITEM = 'UPLOAD_OUTLOOK_ITEM';
export const TRANSLATION_DRAG_A_FILE = 'DRAG_A_FILE';
export const TRANSLATION_GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const TRANSLATION_PLACEHOLDER_SEARCH_INSIDE_CLMI =
    'PLACEHOLDER_SEARCH_INSIDE_CLMI';
export const TRANSLATION_AREA_INBOX = 'AREA_INBOX';
export const TRANSLATION_EMAIL_LABEL = 'EMAIL_LABEL';
export const TRANSLATION_AREA_TASKS = 'AREA_TASKS';
export const TRANSLATION_SEND_BTN = 'SEND_BTN';
export const TRANSLATION_ENUM_ACTIVITYTYPE_APPOINTMENT =
    'ENUM_ACTIVITYTYPE_APPOINTMENT';
export const TRANSLATION_ENTITY_ENGAGEMENT_SINGULAR =
    'ENTITY_ENGAGEMENT_SINGULAR';
export const TRANSLATION_CHANGE_SUMMARY_BTN = 'CHANGE_SUMMARY_BTN';
export const TRANSLATION_MY_PROFILE_TAB = 'MY_PROFILE_TAB';
export const TRANSLATION_SYSTEM_INFO_TAB = 'SYSTEM_INFO_TAB';
export const TRANSLATION_AREA_ADMIN = 'AREA_ADMIN';
export const TRANSLATION_LOG_OUT_BTN = 'LOG_OUT_BTN';
export const TRANSLATION_GLOBAL_SEARCH_FILTER_RESULTS =
    'GLOBAL_SEARCH_FILTER_RESULTS';
export const TRANSLATION_CLOSE_BTN = 'CLOSE_BTN';
export const TRANSLATION_LABEL_MORE = 'LABEL_MORE';
export const TRANSLATION_GLOBAL_UPLOAD_FAILED = 'GLOBAL_UPLOAD_FAILED';
export const TRANSLATION_UPLOAD_PENDING = 'UPLOAD_PENDING';
export const TRANSLATION_DONE_BTN = 'DONE_BTN';
export const TRANSLATION_UPLOAD_BTN = 'UPLOAD_BTN';
export const TRANSLATION_VIEW_BTN = 'VIEW_BTN';
export const TRANSLATION_GLOBAL_UPLOADED_TO_INBOX = 'GLOBAL_UPLOADED_TO_INBOX';
export const TRANSLATION_GLOBAL_UPLOADING = 'GLOBAL_UPLOADING';
export const TRANSLATION_GLOBAL_UPLOAD_FROM_FOLDER =
    'GLOBAL_UPLOAD_FROM_FOLDER';
export const TRANSLATION_GLOBAL_UPLOAD_OPTION = 'GLOBAL_UPLOAD_OPTION';
export const TRANSLATION_GLOBAL_SUCCESSFULLY_UPLOADED =
    'GLOBAL_SUCCESSFULLY_UPLOADED';
export const TRANSLATION_GLOBAL_FILE_TYPE = 'GLOBAL_FILE_TYPE';
export const TRANSLATION_GLOBAL_MSG_FILE = 'GLOBAL_MSG_FILE';
export const TRANSLATION_CASE_COMPLETED_ON = 'CASE_COMPLETED_ON';
export const TRANSLATION_BACK_BTN = 'BACK_BTN';
export const TRANSLATION_FIRST_BTN = 'FIRST_BTN';
export const TRANSLATION_NEXT_BTN = 'NEXT_BTN';
export const TRANSLATION_LAST_BTN = 'LAST_BTN';
export const TRANSLATION_TABLE_HEADER_PRODUCT_NAME =
    'TABLE_HEADER_PRODUCT_NAME';
export const TRANSLATION_TABLE_HEADER_CLIENT_NAME = 'TABLE_HEADER_CLIENT_NAME';
export const TRANSLATION_TABLE_HEADER_CODE = 'TABLE_HEADER_CODE';
export const TRANSLATION_TABLE_HEADER_PRODUCT_TYPE =
    'TABLE_HEADER_PRODUCT_TYPE';
export const TRANSLATION_TABLE_HEADER_STATUS = 'TABLE_HEADER_STATUS';
export const TRANSLATION_TABLE_HEADER_PURPOSE = 'TABLE_HEADER_PURPOSE';
export const TRANSLATION_TABLE_HEADER_OWNER = 'TABLE_HEADER_OWNER';
export const TRANSLATION_TABLE_HEADER_MEMBER_COUNT =
    'TABLE_HEADER_MEMBER_COUNT';
export const TRANSLATION_SVG_SELECT_DASHBOARD = 'SVG_SELECT_DASHBOARD';
export const TRANSLATION_AREA_DASHBOARDS = 'AREA_DASHBOARDS';
export const TRANSLATION_SYSTEM_DASHBOARDS = 'SYSTEM_DASHBOARDS';
export const TRANSLATION_PERSONAL_DASHBOARDS = 'PERSONAL_DASHBOARDS';
export const TRANSLATION_NO_PERSONAL_DASHBORD = 'NO_PERSONAL_DASHBORD';
export const TRANSLATION_WARNING_PLEASE_NOTE = 'WARNING_PLEASE_NOTE';
export const WARING_PENDING_CHANGES = 'WARING_PENDING_CHANGES';
export const TRANSLATION_FORM_HISTORY = 'FORM_HISTORY';
export const TRANSLATION_ENUM_APPSTATUSTYPE_INCOMPLETE =
    'ENUM_APPSTATUSTYPE_INCOMPLETE';
export const TRANSLATION_ENUM_APPSTATUSTYPE_EXPIRED =
    'ENUM_APPSTATUSTYPE_EXPIRED';
export const TRANSLATION_ENUM_APPSTATUSTYPE_INACTIVE =
    'ENUM_APPSTATUSTYPE_INACTIVE';
export const TRANSLATION_ENUM_APPSTATUSTYPE_PENDINGCONFIRMATION =
    'ENUM_APPSTATUSTYPE_PENDINGCONFIRMATION';
export const TRANSLATION_ENUM_APPSTATUSTYPE_CLOSED =
    'ENUM_APPSTATUSTYPE_CLOSED';
export const TRANSLATION_ENUM_APPSTATUSTYPE_PENDING =
    'ENUM_APPSTATUSTYPE_PENDING';
export const TRANSLATION_ENUM_APPSTATUSTYPE_CONFIRMED =
    'ENUM_APPSTATUSTYPE_CONFIRMED';
export const TRANSLATION_ENUM_APPSTATUSTYPE_DORMANT =
    'ENUM_APPSTATUSTYPE_DORMANT';

export const TRANSLATION_ENUM_FORMDATASTATUS_DRAFT =
    'ENUM_FORMDATASTATUS_DRAFT';
export const TRANSLATION_ENUM_FORMDATASTATUS_PUBLISHED =
    'ENUM_FORMDATASTATUS_PUBLISHED';
export const TRANSLATION_ENUM_FORMDATASTATUS_CONFIRMED =
    'ENUM_FORMDATASTATUS_CONFIRMED';
export const TRANSLATION_ENUM_FORMDATASTATUS_HISTORICDRAFT =
    'ENUM_FORMDATASTATUS_HISTORICDRAFT';
export const TRANSLATION_ENUM_FORMDATASTATUS_HISTORICPUBLISHED =
    'ENUM_FORMDATASTATUS_HISTORICPUBLISHED';
export const TRANSLATION_TEAM_ADDED = 'TEAM_ADDED';
export const TRANSLATION_TEAM_OWNER = 'TEAM_OWNER';
export const TRANSLATION_MENU_EDIT = 'MENU_EDIT';
export const TRANSLATION_MENU_DELETE = 'MENU_DELETE';
export const TRANSLATION_LEGEND_ITEM_NO_SLA_DATE_SET =
    'LEGEND_ITEM_NO_SLA_DATE_SET';
export const TRANSLATION_LEGEND_ITEM_ISSUE_REPORTED =
    'LEGEND_ITEM_ISSUE_REPORTED';
export const TRANSLATION_LEGEND_ITEM_DUE = 'LEGEND_ITEM_DUE';
export const TRANSLATION_LEGEND_ITEM_ON_TIME = 'LEGEND_ITEM_ON_TIME';
export const TRANSLATION_TABLE_HEADER_RELATED_TO = 'TABLE_HEADER_RELATED_TO';
export const TRANSLATION_TABLE_HEADER_ASSIGNED_TO = 'TABLE_HEADER_ASSIGNED_TO';
export const TRANSLATION_TABLE_HEADER_CREATED_BY = 'TABLE_HEADER_CREATED_BY';
export const TRANSLATION_TABLE_HEADER_CREATED_ON = 'TABLE_HEADER_CREATED_ON';
export const TRANSLATION_TABLE_HEADER_DUE_DATE = 'TABLE_HEADER_DUE_DATE';
export const TRANSLATION_DUE_DATE = 'DUE_DATE';
export const TRANSLATION_DATE_EXPIRES = 'DATE_EXPIRES';
export const TRANSLATION_APPROVAL_MODE_NO_APPS = 'APPROVAL_MODE_NO_APPS';
export const TRANSLATION_MODAL_TITLE_CASE_TYPE = 'MODAL_TITLE_CASE_TYPE';
export const TRANSLATION_TABLE_HEADER_DUE = 'TABLE_HEADER_DUE';
export const TRANSLATION_TABLE_HEADER_NEXT_DUE = 'TABLE_HEADER_NEXT_DUE';
export const TRANSLATION_TABLE_HEADER_REGARDING = 'TABLE_HEADER_REGARDING';
export const TRANSLATION_TABLE_HEADER_SUBJECT = 'TABLE_HEADER_SUBJECT';
export const TRANSLATION_TABLE_HEADER_IDENTIFIER = 'TABLE_HEADER_IDENTIFIER';
export const TRANSLATION_TABLE_HEADER_SLA = 'TABLE_HEADER_SLA';
export const TRANSLATION_TABLE_HEADER_DATE_CREATED =
    'TABLE_HEADER_DATE_CREATED';
export const TRANSLATION_TABLE_HEADER_DATE_MODIFIED =
    'TABLE_HEADER_DATE_MODIFIED';
export const TRANSLATION_TABLE_HEADER_LIST = 'TABLE_HEADER_LIST';
export const TRANSLATION_TABLE_HEADER_DOCUMENT_TEMPLATE =
    'TABLE_HEADER_DOCUMENT_TEMPLATE';
export const TRANSLATION_TABLE_HEADER_EVENT_DATE = 'TABLE_HEADER_EVENT_DATE';
export const TRANSLATION_TABLE_TABLE_HEADER_LOCATION = 'TABLE_HEADER_LOCATION';
export const TRANSLATION_PERSONAL_VIEWS = 'PERSONAL_VIEWS';
export const TRANSLATION_SYSTEM_VIEWS = 'SYSTEM_VIEWS';
export const TRANSLATION_ITEMS_LABEL = 'ITEMS_LABEL';
export const TRANSLATION_SELECT_LABEL = 'SELECT_LABEL';
export const TRANSLATION_FILTER_ADD_CONDITION = 'FILTER_ADD_CONDITION';
export const TRANSLATION_FILTER_LABEL = 'FILTER_LABEL';
export const TRANSLATION_APPLY_BTN = 'APPLY_BTN';
export const TRANSLATION_PLACEHOLDER_NEW_FILTER = 'PLACEHOLDER_NEW_FILTER';
export const TRANSLATION_FILTER_WHERE = 'FILTER_WHERE';
export const TRANSLATION_FILTER_AND = 'FILTER_AND';
export const TRANSLATION_LIST_TYPE = 'LIST_TYPE';
export const TRANSLATION_LIST_OWNER = 'LIST_OWNER';
export const TRANSLATION_LIST_CREATED_ON = 'LIST_CREATED_ON';
export const TRANSLATION_TAB_DETAILS = 'TAB_DETAILS';
export const TRANSLATION_TAB_LIST_MEMBERS = 'TAB_LIST_MEMBERS';
export const TRANSLATION_TAB_NETWORK_CHART = 'TAB_NETWORK_CHART';
export const TRANSLATION_TAB_DASHBOARD = 'TAB_DASHBOARD';
export const TRANSLATION_TABLE_HEADER_NEXT_MEETING =
    'TABLE_HEADER_NEXT_MEETING';
export const TRANSLATION_TABLE_HEADER_DATE_IMPORTED =
    'TABLE_HEADER_DATE_IMPORTED';
export const TRANSLATION_TABLE_HEADER_IMPORTED_BY = 'TABLE_HEADER_IMPORTED_BY';
export const TRANSLATION_TABLE_HEADER_TOTAL_ROWS_COUNT =
    'TABLE_HEADER_TOTAL_ROWS_COUNT';
export const TRANSLATION_TABLE_HEADER_DATE_COMPLETED =
    'TABLE_HEADER_DATE_COMPLETED';
export const TRANSLATION_TABLE_HEADER_FAILED_ROWS_COUNT =
    'TABLE_HEADER_FAILED_ROWS_COUNT';
export const TRANSLATION_TABLE_HEADER_DATE_REQUESTED =
    'TABLE_HEADER_DATE_REQUESTED';
export const TRANSLATION_TABLE_HEADER_DATE_GENERATED =
    'TABLE_HEADER_DATE_GENERATED';
export const TRANSLATION_TABLE_HEADER_REQUESTED_BY =
    'TABLE_HEADER_REQUESTED_BY';
export const TRANSLATION_TABLE_HEADER_CONTACT_POINT =
    'TABLE_HEADER_CONTACT_POINT';
export const TRANSLATION_TABLE_HEADER_NUMBER_OF_COPIES =
    'TABLE_HEADER_NUMBER_OF_COPIES';
export const TRANSLATION_TABLE_HEADER_MANDATORY = 'TABLE_HEADER_MANDATORY';
export const TRANSLATION_TABLE_HEADER_VALID_FROM = 'TABLE_HEADER_VALID_FROM';
export const TRANSLATION_TABLE_HEADER_VALID_TO = 'TABLE_HEADER_VALID_TO';
export const TRANSLATION_GENERATE_LABEL = 'GENERATE_LABEL';
export const TRANSLATION_HIDDEN_STATUSES_LABEL = 'HIDDEN_STATUSES_LABEL';
export const TRANSLATION_PLACEHOLDER_NONE = 'PLACEHOLDER_NONE';
export const TRANSLATION_ENUM_MAILINGINSTRUCTIONSTATUSTYPE_DRAFT =
    'ENUM_MAILINGINSTRUCTIONSTATUSTYPE_DRAFT';
export const TRANSLATION_ENUM_MAILINGINSTRUCTIONSTATUSTYPE_INACTIVE =
    'ENUM_MAILINGINSTRUCTIONSTATUSTYPE_INACTIVE';
export const TRANSLATION_ENUM_MAILINGINSTRUCTIONSTATUSTYPE_ACTIVE =
    'ENUM_MAILINGINSTRUCTIONSTATUSTYPE_ACTIVE';
export const TRANSLATION_GROUP_BY_DOC_TYPE = 'GROUP_BY_DOC_TYPE';
export const TRANSLATION_GROUP_BY_CHANNEL = 'GROUP_BY_CHANNEL';
export const TRANSLATION_GROUP_BY_CURRENCY = 'GROUP_BY_CURRENCY';
export const TRANSLATION_GROUP_BY_FREQUENCY = 'GROUP_BY_FREQUENCY';
export const TRANSLATION_GROUP_BY_PORTFOLIO = 'GROUP_BY_PORTFOLIO';
export const TRANSLATION_GROUP_BY_PARTY = 'GROUP_BY_PARTY';
export const TRANSLATION_TABLE_HEADER_LANGUAGE = 'TABLE_HEADER_LANGUAGE';
export const TRANSLATION_PORTAL_AREA_CHECKLIST = 'PORTAL_AREA_CHECKLIST';
export const TRANSLATION_PORTAL_DESCRIPTION_PART1 =
    'PORTAL_CHECKLIST_DESCRIPTION_PART1';
export const TRANSLATION_PORTAL_DESCRIPTION_PART2 =
    'PORTAL_CHECKLIST_DESCRIPTION_PART2';
export const TRANSLATION_PORTAL_DESCRIPTION_TEAMLINK =
    'PORTAL_DESCRIPTION_TEAMLINK';
export const TRANSLATION_PLACEHOLDER_SEARCH_DOCUMENTS =
    'PLACEHOLDER_SEARCH_DOCUMENTS';
export const TRANSLATION_SELECT_DOCUMENT = 'SELECT_DOCUMENT';
export const TRANSLATION_SVG_TEXT_NO_DOC_MATCHING = 'SVG_TEXT_NO_DOC_MATCHING';
export const TRANSLATION_YES_BTN = 'YES_BTN';
export const TRANSLATION_NO_BTN = 'NO_BTN';
export const TRANSLATION_TITLE_REMOVE_TEAM_MEMBER = 'TITLE_REMOVE_TEAM_MEMBER';
export const TRANSLATION_TITLE_DELETE_ACTIVITY = 'TITLE_DELETE_ACTIVITY';
export const TRANSLATION_TITLE_CANCEL_CASE = 'TITLE_CANCEL_CASE';
export const TRANSLATION_TITLE_CANCEL_ACTIVITY = 'TITLE_CANCEL_ACTIVITY';
export const TRANSLATION_TITLE_DELETE_DASHBOARD = 'TITLE_DELETE_DASHBOARD';
export const TRANSLATION_TITLE_DELETE_OPPORTUNITY = 'TITLE_DELETE_OPPORTUNITY';
export const TRANSLATION_TITLE_DELETE_DOCUMENT = 'TITLE_DELETE_DOCUMENT';
export const TRANSLATION_TITLE_DELETE = 'TITLE_DELETE';
export const TRANSLATION_TITLE_SHARE_WITH_CLIENT = 'TITLE_SHARE_WITH_CLIENT';
export const TRANSLATION_TITLE_CANCEL_DOC_REQUIRMENT =
    'TITLE_CANCEL_DOC_REQUIRMENT';
export const TRANSLATION_TITLE_DELETE_DOC_TYPE = 'TITLE_DELETE_DOC_TYPE';
export const TRANSLATION_DIALOG_REMOVE_TEAM_MEMBER =
    'DIALOG_REMOVE_TEAM_MEMBER';
export const TRANSLATION_POPOVER_ID_COPIED = 'POPOVER_ID_COPIED';
export const TRANSLATION_IDENTIFIER_CLMI_ID = 'IDENTIFIER_CLMI_ID';
export const TRANSLATION_DIALOG_CANCEL_ACTIVITY = 'DIALOG_CANCEL_ACTIVITY';
export const TRANSLATION_DIALOG_CANCEL_CASE = 'DIALOG_CANCEL_CASE';
export const TRANSLATION_DIALOG_DELETE_DOC_REQUIREMENT =
    'DIALOG_DELETE_DOC_REQUIREMENT';
export const TRANSLATION_DIALOG_CANCEL_DOC_REQUIREMENT =
    'DIALOG_CANCEL_DOC_REQUIREMENT';
export const TRANSLATION_DIALOG_RUN_PEP_SANCTIONS_CHECK =
    'DIALOG_RUN_PEP_SANCTIONS_CHECK';
export const TRANSLATION_DIALOG_DELETE_DASHBOARD = 'DIALOG_DELETE_DASHBOARD';
export const TRANSLATION_DIALOG_GENERAL_DELETE_MESSAGE =
    'DIALOG_GENERAL_DELETE_MESSAGE';
export const TRANSLATION_DIALOG_DELETE_BATCH_LIST = 'DIALOG_DELETE_BATCH_LIST';
export const TRANSLATION_DIALOG_DELETE_BATCH_MESSAGE_ROW =
    'DIALOG_DELETE_BATCH_MESSAGE_ROW';
export const TRANSLATION_DIALOG_REPLAY_MESSAGE_BATCH =
    'DIALOG_REPLAY_MESSAGE_BATCH';
export const TRANSLATION_DIALOG_DELETE_VIEW = 'DIALOG_DELETE_VIEW';
export const TRANSLATION_DIALOG_DELETE_MULTIPLE_MESSAGE =
    'DIALOG_DELETE_MULTIPLE_MESSAGE';
export const TRANSLATION_DIALOG_REPLAY_MULTIPLE_MESSAGE =
    'DIALOG_REPLAY_MULTIPLE_MESSAGE';
export const TRANSLATION_DIALOG_DELETE_DOC = 'DIALOG_DELETE_DOC';
export const TRANSLATION_DIALOG_DELETE_OPPORTUNITY =
    'DIALOG_DELETE_OPPORTUNITY';
export const TRANSLATION_DIALOG_SHARE_APP_WITH_CLIENT =
    'DIALOG_SHARE_APP_WITH_CLIENT';
export const TRANSLATION_DIALOG_REMOVE_CLIENT_ROLE =
    'DIALOG_REMOVE_CLIENT_ROLE';
export const TRANSLATION_DIALOG_DELETE_ACTIVE_CLIENT_ROLE =
    'DIALOG_DELETE_ACTIVE_CLIENT_ROLE';
export const TRANSLATION_TITLE_REMOVE_CLIENT_ROLE = 'TITLE_REMOVE_CLIENT_ROLE';
